import { flatten, noop, uniqBy } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { queryClient } from "@circle-react/providers/QueryClient/reactQueryConfig";

export const PARTICIPANTS_DEFAULT_PER_PAGE = 100;

export const useChatParticipantsApi = (
  chatRoomUuid,
  params = {
    shouldDisableCache: false,
    per_page: PARTICIPANTS_DEFAULT_PER_PAGE,
  },
) => {
  const queryKey = ["chat-participants", chatRoomUuid];
  const { shouldDisableCache, ...apiParams } = params;

  const fetchParticipants = page =>
    reactQueryGet(
      internalApi.chatRoomParticipants.index({
        uuid: chatRoomUuid,
        params: { page, ...apiParams },
      }),
    );

  const getCachedParticipants = () => {
    const cache = queryClient.getQueryState({
      queryKey,
    });
    const { data } = cache || {};
    const participants = flatten((data?.pages || []).map(page => page.records));
    return uniqBy(participants, "id");
  };

  const {
    data,
    isLoading,
    refetch: refetchChatParticipants,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchParticipants(pageParam),
    {
      select: ({ pages = [] }) => ({
        participants: uniqBy(
          flatten((pages || []).map(page => page.records)),
          "id",
        ),
        totalCount: pages[0].count,
      }),
      enabled:
        (getCachedParticipants()?.length === 0 || shouldDisableCache) &&
        Boolean(chatRoomUuid),
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const { totalCount = 0 } = data || {};

  return {
    chatParticipants: getCachedParticipants(),
    isLoading,
    refetchChatParticipants: chatRoomUuid ? refetchChatParticipants : noop,
    hasNextPage: hasNextPage,
    totalCount,
    fetchNextPage,
  };
};
